import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from '../images/SheetPlanner_logo.svg'

const Content = styled('div')`
  width: clamp(400px, 50vw, 960px);
  height: calc(100vh - 300px);
  margin: 0 auto;
  position:relative;
  top: 160px;
  counter-reset: section;
  & > *{
    text-align: left;
  }
  h3 {
    margin-top: 24px;
      counter-increment: section;
    &:before{
      content: counter(section) ". ";
    }
  }
  & span.link {
    display: block;
    margin-top: 48px;
  }
`

const SuccessPage = () => (
  <Layout noHeaderFooter>
    <SEO title={'Sheetplanner - Successfully subscribed to newsletter'}/>
    <Content>
      <img src={Logo} alt={"SheetPlanner Logo"} />
      <h1 style={{ "textAlign": "center" }}>Thanks for subscribing!</h1>
    </Content>
  </Layout>
)

export default SuccessPage
